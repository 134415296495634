import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { navigateToUrl } from "single-spa";
import "primeng/resources/primeng.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import Keycloak from "keycloak-js";
import { changeTheme } from "./utils/theme";

const _keycloak = new Keycloak({
  url: "https://auth.orbita.com.co/",
  realm: "Orbita",
  clientId: "mf-portal",
});

const layoutData = {
  props: {
    keycloak: _keycloak,
    theme: () => {
      changeTheme();
    },
  },
  loaders: {},
};

_keycloak
  .init({ onLoad: "login-required" })
  .then((authenticated) => {
    if (authenticated) {
      const routes = constructRoutes(microfrontendLayout, layoutData);
      const applications = constructApplications({
        routes,
        loadApp({ name }) {
          if (name === "orbita-menu" || name === "orbita-portal") {
            return System.import(name);
          }
          const access = _keycloak.resourceAccess[name];
          if (access?.roles && access.roles.length > 0) {
            return System.import(name);
          } else {
            navigateToUrl("/sge/not-found");
          }
        },
      });

      const layoutEngine = constructLayoutEngine({ routes, applications });
      applications.forEach(registerApplication);
      layoutEngine.activate();
      start();
    }
  })
  .catch((error) => {
    console.error("Error al inicializar Keycloak:", error);
  });
