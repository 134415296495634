import Cookies from "js-cookie";

let theme_orbita = Cookies.get("theme-orbita");

function applyTheme(theme) {
  let linkElement = document.getElementById("theme-css-link");
  linkElement.setAttribute("href", `/../assets/${theme}.css`);
  let faviconElement = document.querySelector("link[rel='icon']");
  if (theme === "theme-claro") {
    faviconElement.setAttribute(
      "href",
      "https://static.orbita.com.co/public/general/images/Ícono-Blanco_Órbita.ico"
    );
  } else if (theme === "theme-oscuro") {
    faviconElement.setAttribute(
      "href",
      "https://static.orbita.com.co/public/general/images/Ícono-Negro_Órbita.ico"
    );
  }
}

if (theme_orbita) {
  applyTheme(theme_orbita);
} else {
  theme_orbita = "theme-oscuro";
  Cookies.set("theme-orbita", theme_orbita, {
    secure: true,
    sameSite: "Strict",
  });
  applyTheme(theme_orbita);
}

export function changeTheme() {
  if (theme_orbita === "theme-claro") {
    theme_orbita = "theme-oscuro";
  } else if (theme_orbita === "theme-oscuro") {
    theme_orbita = "theme-claro";
  }
  Cookies.set("theme-orbita", theme_orbita, {
    secure: true,
    sameSite: "Strict",
  });
  applyTheme(theme_orbita);
}
